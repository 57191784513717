var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('driverCompensation.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('driverCompensation.pageTitle'),
                    active: true
                }
            ]}}),_c('ts-loading-banner',{attrs:{"loading":_vm.loading}},[_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-space-x-3"},[_c('div',{staticClass:"tw-flex tw-items-center tw-space-x-3"},[(_vm.$can('create-driver-compensations'))?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t("addNew"))+" ")]):_vm._e(),_c('a-range-picker',{staticStyle:{"width":"200px"},attrs:{"allowClear":false,"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY"},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('ts-checkbox',{on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.isClear),callback:function ($$v) {_vm.isClear=$$v},expression:"isClear"}},[_vm._v(" "+_vm._s(_vm.$t("shopCompensation.cleared"))+" ")])],1)]),_c('a-tooltip',{attrs:{"title":_vm.$t('driverCompensation.search')}},[_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('driverCompensation.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('div',{staticClass:"tw-overflow-x-scroll"},[_c('ts-table',{ref:"tableList",attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading,"expandable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var record = ref.record;
return [_c('td',{staticClass:"tw-text-left"},[_vm._v(" "+_vm._s(record.transaction_date)+" ")]),_c('td',{staticClass:"tw-text-left"},[_vm._v(" "+_vm._s(record.driver_code)+" ")]),_c('td',{staticClass:"tw-text-left"},[_vm._v(" "+_vm._s(record.driver_name)+" ")]),_c('td',{staticClass:"tw-text-center"},[_vm._v(_vm._s(record.sex))]),_c('td',[_vm._v(_vm._s(record.phone1))]),_c('td',{staticClass:"tw-text-right"},[_c('ts-accounting-format',{attrs:{"digit":2,"value":record.pickup_fee}})],1),_c('td',{staticClass:"tw-text-right"},[_c('ts-accounting-format',{attrs:{"digit":2,"value":record.driver_fee}})],1),_c('td',{staticClass:"tw-text-right"},[_c('ts-accounting-format',{attrs:{"digit":2,"value":record.amount_tobe_clear}})],1),_c('td',{staticClass:"tw-text-center"},[(record.is_driver_clear)?_c('span',{staticClass:"badge bg-primary"},[_vm._v("Yes")]):_c('span',{staticClass:"badge bg-secondary"},[_vm._v("No")])]),_c('td',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(record.driver_clear_by)+" ")])]}},{key:"expand",fn:function(ref){
            var record = ref.record;
return [_c('Details',{attrs:{"record":record.details}})]}},{key:"tfooter",fn:function(){return [_c('tr',{staticClass:"tw-text-blue-500 tw-text-white tw-font-semibold"},[_c('td',{staticClass:"tw-text-right",attrs:{"colspan":"7"}},[_c('b',{staticStyle:{"border":"none"}},[_vm._v(_vm._s(_vm.$t("total"))+" :")])]),_c('td',{staticClass:"tw-border"},[_c('ts-accounting-format',{staticStyle:{"border":"none"},attrs:{"digit":2,"value":_vm.sumTotal('pickup_fee')}})],1),_c('td',{staticClass:"tw-border"},[_c('ts-accounting-format',{staticStyle:{"border":"none"},attrs:{"digit":2,"value":_vm.sumTotal('driver_fee')}})],1),_c('td',{staticClass:"tw-border"},[_c('ts-accounting-format',{staticStyle:{"border":"none"},attrs:{"digit":2,"value":_vm.sumTotal('amount_tobe_clear')}})],1)])]},proxy:true}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }