<template>
    <div>
        <ts-page-title
            :title="$t('driverCompensation.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('driverCompensation.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-loading-banner :loading="loading">
            <ts-panel>
                <ts-panel-wrapper>
                    <div class="tw-flex tw-justify-between">
                        <div class="tw-flex tw-space-x-3">
                            <div class="tw-flex tw-items-center tw-space-x-3">
                                <a-button
                                    type="primary"
                                    @click="addNew"
                                    v-if="$can('create-driver-compensations')"
                                >
                                    {{ $t("addNew") }}
                                </a-button>
                                <a-range-picker
                                    v-model="dateRange"
                                    :allowClear="false"
                                    format="DD-MM-YYYY"
                                    valueFormat="DD-MM-YYYY"
                                    style="width: 200px"
                                    @change="fetchData()"
                                />
                                <ts-checkbox
                                    v-model="isClear"
                                    @change="fetchData()"
                                >
                                    {{ $t("shopCompensation.cleared") }}
                                </ts-checkbox>
                            </div>
                        </div>
                        <a-tooltip :title="$t('driverCompensation.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('driverCompensation.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </ts-panel-wrapper>
                <div class="tw-overflow-x-scroll">
                    <ts-table
                        :columns="columns"
                        :records="resources"
                        :loading="loading"
                        ref="tableList"
                        :expandable="true"
                    >
                        <template v-slot="{ record }">
                            <td class="tw-text-left">
                                {{ record.transaction_date }}
                            </td>
                            <td class="tw-text-left">
                                {{ record.driver_code }}
                            </td>
                            <td class="tw-text-left">
                                {{ record.driver_name }}
                            </td>
                            <td class="tw-text-center">{{ record.sex }}</td>
                            <td>{{ record.phone1 }}</td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    :digit="2"
                                    :value="record.pickup_fee"
                                />
                            </td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    :digit="2"
                                    :value="record.driver_fee"
                                />
                            </td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    :digit="2"
                                    :value="record.amount_tobe_clear"
                                />
                            </td>
                            <td class="tw-text-center">
                                <span
                                    class="badge bg-primary"
                                    v-if="record.is_driver_clear"
                                    >Yes</span
                                >
                                <span class="badge bg-secondary" v-else
                                    >No</span
                                >
                            </td>
                            <td class="tw-text-center">
                                {{ record.driver_clear_by }}
                            </td>
                        </template>
                        <template v-slot:expand="{ record }">
                            <Details :record="record.details" />
                        </template>
                        <template v-slot:tfooter>
                            <tr
                                class="tw-text-blue-500 tw-text-white tw-font-semibold"
                            >
                                <td colspan="7" class="tw-text-right">
                                    <b style="border:none"
                                        >{{ $t("total") }} :</b
                                    >
                                </td>
                                <td class="tw-border">
                                    <ts-accounting-format
                                        style="border:none"
                                        :digit="2"
                                        :value="sumTotal('pickup_fee')"
                                    />
                                </td>
                                <td class="tw-border">
                                    <ts-accounting-format
                                        style="border:none"
                                        :digit="2"
                                        :value="sumTotal('driver_fee')"
                                    />
                                </td>
                                <td class="tw-border">
                                    <ts-accounting-format
                                        style="border:none"
                                        :digit="2"
                                        :value="sumTotal('amount_tobe_clear')"
                                    />
                                </td>
                            </tr>
                        </template>
                    </ts-table>
                </div>
								<div class="d-flex justify-content-end tw-p-4">
										<ts-pagination
												v-model="pagination"
												@navigate="fetchData"
										></ts-pagination>
								</div>
            </ts-panel>
        </ts-loading-banner>
    </div>
</template>

<script>
import { debounce, sumBy } from "lodash";
import { mapState } from "vuex";
import Details from "./detail.vue";

export default {
    name: "driverCompensate",
    components: {
        Details
    },
    data() {
        return {
            loading: false,
            driver_id: []
        };
    },
    computed: {
        ...mapState("finance/driverCompensation", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.finance.driverCompensation.search;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/driverCompensation/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "finance/driverCompensation/RESET_CURRENT_PAGE"
                );
            }
        },
        dateRange: {
            get() {
                return this.$store.state.finance.driverCompensation.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/driverCompensation/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        isClear: {
            get() {
                return this.$store.state.finance.driverCompensation.is_clear;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/driverCompensation/SET_IS_CLEARED",
                    newValue
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("driverCompensation.transactionDate"),
                    style: "text-align:left"
                },
                {
                    name: this.$t("driverCompensation.driverCode"),
                    style: "text-align:left"
                },
                {
                    name: this.$t("driverCompensation.driverName"),
                    style: "text-align:left"
                },
                {
                    name: this.$t("driverCompensation.sex"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("driverCompensation.phone"),
                    style: "text-align:left"
                },
                {
                    name: this.$t("driverCompensation.pickupFee"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("driverCompensation.driverFee"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("driverCompensation.amountTobeClear"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("driverCompensation.isClear"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("driverCompensation.clearBy"),
                    style: "text-align:center"
                }
            ];
        },
        sumTotal: vm => column => {
            var amount = sumBy(vm.resources, column);

            return amount;
        }
    },
    methods: {
        addNew() {
            this.$router.push({ name: "driver-compensation-create" });
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("finance/driverCompensation/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("finance/driverCompensation/RESET_STATE");
        next();
    }
};
</script>
