<template>
  <table class="tw-w-full">
    <thead>
      <tr class="tw-bg-blue-400 tw-text-white">
        <th class="tw-px-2 tw-py-1 tw-border tw-whitespace-nowrap">
          {{ $t("driverCompensation.transactionDate") }}
        </th>
        <th class="tw-px-2 tw-py-1 tw-border tw-whitespace-nowrap">
          {{ $t("driverCompensation.voucherNo") }}
        </th>
        <th
          class="tw-px-2 tw-py-1 tw-border tw-text-center tw-whitespace-nowrap"
        >
          {{ $t("driverCompensation.driverCode") }}
        </th>
        <th
          class="tw-px-2 tw-py-1 tw-border tw-text-center tw-whitespace-nowrap"
          style="width: 200px"
        >
          {{ $t("driverCompensation.driverName") }}
        </th>
        <th
          class="tw-px-2 tw-py-1 tw-border tw-text-center tw-whitespace-nowrap"
          style="width: 75px"
        >
          {{ $t("driverCompensation.sex") }}
        </th>
        <th
          class="tw-px-2 tw-py-1 tw-border tw-text-center tw-whitespace-nowrap"
          style="width: 200px"
        >
          {{ $t("driverCompensation.shopName") }}
        </th>
        <th
          class="tw-px-2 tw-py-1 tw-border tw-text-center tw-whitespace-nowrap"
          style="width: 200px"
        >
          {{ $t("driverCompensation.deliveryNumber") }}
        </th>
        <th
          class="tw-px-2 tw-py-1 tw-border tw-text-center tw-whitespace-nowrap"
          style="width: 150px"
        >
          {{ $t("driverCompensation.serviceType") }}
        </th>
        <th
          class="tw-px-2 tw-py-1 tw-border tw-text-center tw-whitespace-nowrap"
          style="width: 150px"
        >
          {{ $t("driverCompensation.pickupFee") }}
        </th>
        <th
          class="tw-px-2 tw-py-1 tw-border tw-text-center tw-whitespace-nowrap"
          style="width: 150px"
        >
          {{ $t("driverCompensation.driverFee") }}
        </th>
        <th
          class="tw-px-2 tw-py-1 tw-border tw-text-center tw-whitespace-nowrap"
          style="width: 150px"
        >
          {{ $t("driverCompensation.amountTobeClear") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(ep, index) in record" :key="index">
        <td class="tw-text-left tw-whitespace-nowrap tw-border tw-py-1 tw-px-2">
          {{ ep.transaction_date }}
        </td>
        <td class="tw-whitespace-nowrap tw-py-1 tw-border tw-px-2">
          {{ ep.voucher_no }}
        </td>
        <td
          class="tw-text-center tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
        >
          {{ ep.driver_code }}
        </td>
        <td
          class="tw-text-center tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
        >
          {{ ep.driver_name }}
        </td>
        <td
          class="tw-text-center tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
        >
          {{ ep.sex }}
        </td>
        <td
          class="tw-text-center tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
        >
          {{ ep.shop_name }}
        </td>
        <td
          class="tw-text-center tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
        >
          {{ ep.delivery_number }}
        </td>
        <td
          class="tw-text-center tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
        >
          {{ ep.service_type }}
        </td>
        <td
          class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
        >
          <ts-accounting-format
            currency="$"
            :digit="0"
            :value="ep.pickup_fee"
          />
        </td>
        <td
          class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
        >
          <ts-accounting-format
            currency="$"
            :digit="0"
            :value="ep.driver_fee"
          />
        </td>
        <td
          class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
        >
          <ts-accounting-format
            currency="$"
            :digit="0"
            :value="ep.amount_tobe_clear"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["record"],
};
</script>
